import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DocumentTitle from "react-document-title";
import { viewData } from "../../apiservice/ApiServices";
import { baseUrlImage } from "../../index";
import { baseUrl } from "../../index";
import { Link } from "react-router-dom";
import Section from "./Section";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";

const RatnaDetail = () => {
  const formData = new FormData();
  const [allRatna, setAllRatna] = useState([]);
  const [ratna, setRatna] = useState([]);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [detail, setDetail] = useState("");
  const close_pupup = document.querySelector(".close_pupup");

  const [isValid, setIsValid] = useState(true);


  const validatePhoneNumber = (value) => {
    const phoneRegex = /^\d{10}$/;
    setIsValid(phoneRegex.test(value));
  };

  const handleChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); 
    setPhone(value);
    validatePhoneNumber(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    formData.append("Name", name);
    formData.append("Mobile", phone);
    formData.append("Details", detail);
   
    try {
      const response = await fetch(`${baseUrl}inquiryForm`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      if (data && data.message === "Your Inquiry Successfully Registered") {
        toast.success(data && data.message, {
          style: {
            backgroundColor: 'green',
            color: 'white',
          },
        });
        close_pupup.click();
                setName("");
        setPhone("");
        setDetail("");
      } else {
        toast.error(data && data.result.message, {
          style: {
            backgroundColor: 'red',
            color: 'white',
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  

  const { id } = useParams();
  const fetchData = async (id) => {
    const data = await viewData(`RatnaDetail/${id}`);
    setAllRatna(data.allratna);
    setRatna(data.ratna[0]);
  };
  
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData(id);
  }, [id]);

    useEffect(() => {
    document.title = ratna.MetaTitle
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.content = (ratna.MetaDescription);
    } else {
      const meta = document.createElement('meta');
      meta.name = 'description';
      meta.content = "वैदिक ज्योतिष केंद्र में वैदिक ज्योतिष की ज्ञान संपदा का अन्वेषण करें। विशेषज्ञ ज्योतिषीय मार्गदर्शन, सटीक जन्म कुंडली विश्लेषण और व्यक्तिगत राशिफल भविष्यवाणियां प्राप्त करें। हमारी व्यापक ज्योतिष सेवाओं के साथ अपने भाग्य को खोलें।" ;
      document.head.appendChild(meta);
    }
  }, [ratna]);

  return (
    // <DocumentTitle title={`${ratna.Title} | Vedic Astro`}>
      <>
        <section className="breadCreamp" >
          <div className="container">
            <div className="breadTitle">
              <h1>{ratna.Title}</h1>
             
            </div>
          </div>
        </section>
        <Section image={ratna.Image} description={ratna.Detail} />

        <section className="rashiRatn"  data-aos="zoom-in">
          <div className="container">
            <div className="row">
              <div className="ratnGrid">
                {allRatna &&
                  allRatna.map((val, index) => (
                    <div className="ratnCard" key={index}>
                      <figure>
                        <img
                          src={`${baseUrlImage}uploads/createpage/${val.Image}`}
                          alt=""
                        />
                      </figure>
                      <div className="ratnText">
                        <h4>{val.Title}</h4>
                        <p dangerouslySetInnerHTML={{ __html: val.Detail }} />
                        <div className="rashiRead">
                          <Link to="/inquiry" data-toggle="modal" data-target="#plan-detail">
                            Inquiry Now
                          </Link>
                          <Link to={`/ratna/${val.CreatePageAlias}`}>Read More</Link>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </section>

        {/* Popup window for enquiry */}
        <div
          id="plan-detail"
          className="modal fade"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="my-modal-title"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button className="close close_pupup" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="formInquiry popupInquiry">
                <h5>REQUEST A CONSULTING</h5>
                <p>
                  आपकी समस्या का समाधान यहा उपलब्ध है |समस्या के समाधान के लिए
                  हमसे संपर्क करे |
                </p>
                <div className="formCard">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <input
                        type="text"
                        onChange={(e) => setName(e.target.value)}
                        className="form-control"
                        placeholder="Full Name"
                        name="name"
                        required
                        value={name}
                      />
                    </div>
                    <div className="form-group">
                    <input
      type="text"
      onChange={handleChange}
      maxLength={10}
      className={`form-control ${isValid ? '' : 'invalid'}`}
      placeholder="Phone No. (10 digits)"
      name="phone"
      required
      value={phone}
      pattern="\d*"
      title={isValid ? '' : 'Phone number must be exactly 10 digits.'}
    />
                    </div>
                    <div className="form-group">
                      <textarea
                        onChange={(e) => setDetail(e.target.value)}
                        placeholder="Message"
                        value={detail}
                      />
                    </div>
                    <button type="submit" name="enquiry" className="btn-started">
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <ToastContainer autoClose={2000} />
      </>
    // </DocumentTitle>
  );
};

export default RatnaDetail;
