import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import OwlCarousel from "react-owl-carousel";
import { baseUrl } from "../../index";
import { baseUrlImage } from "../../index";
import { viewData } from "../../apiservice/ApiServices";
import DocumentTitle from "react-document-title";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";

const Home = () => {
  const formData = new FormData();
  const inqueryForm = new FormData();
  const close_pupup = document.querySelector(".close_pupup");

  const [hawanPoojanData, setHawanPoojanData] = useState([]);
  const [rashiData, setRashiData] = useState([]);
  const [ratnaData, setRatnaData] = useState([]);
  const [yantraData, setYantraData] = useState([]);
  const [sliderData, setSliderData] = useState([]);
  const [consultData, setConsultData] = useState([]);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [dob, setDob] = useState("");
  const [detail, setDetail] = useState("");
  const [inquerynName, setInquerynName] = useState("");
  const [inquerynPhone, setInquerynPhone] = useState("");
  const [inquerynDetail, setInquerynDetail] = useState("");

  // for freekundli //
  const [fname, setFName] = useState("");
  const [fphone, setFPhone] = useState("");
  const [fdetail, setDFetail] = useState("");
  const [fdob, setFDob] = useState("");
  const [ftime, setFTime] = useState("");
  const [fgendar, setFGendar] = useState("");
  const [fplace, setFPlace] = useState("");
  const [validationMessage, setValidationMessage] = useState("");

  const [isValid, setIsValid] = useState(true);
  const [isfValid, setFIsValid] = useState(true);

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const validatePhoneNumber = (value) => {
    const phoneRegex = /^\d{10}$/;
    setIsValid(phoneRegex.test(value));
  };

  const handleChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    setPhone(value);
    validatePhoneNumber(value);
  };

  const validateFPhoneNumber = (value) => {
    const phoneRegex = /^\d{10}$/;
    setFIsValid(phoneRegex.test(value));
  };

  const handlefChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    setFPhone(value);
    validateFPhoneNumber(value);
  };

  // -----------> FORM SUBMIT <-------------

  const handleSubmit = async (e) => {
    e.preventDefault();
    formData.append("Name", name);
    formData.append("Mobile", phone);
    formData.append("DOB", dob);
    formData.append("Details", detail);

    try {
      const response = await fetch(`${baseUrl}ContactUs`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      if (data && data.message === "Your Inquiry Successfully Registered") {
        toast.success(data && data.message, {
          style: {
            backgroundColor: "green",
            color: "white",
          },
        });
        setName("");
        setPhone("");
        setDob("");
        setDetail("");
      } else {
        toast.error(data && data.result.message, {
          style: {
            backgroundColor: "red",
            color: "white",
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("Name", fname);
    formData.append("Mobile", fphone);
    formData.append("Dob", fdob);
    formData.append("Time", ftime);
    formData.append("Place", fplace);
    formData.append("Gendar", fgendar);
    formData.append("Details", fdetail);

    try {
      const response = await fetch(`${baseUrl}FreeKundle`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      // console.log(data.message);
      if (data && data.message === "Successfully Registered") {
        toast.success(data.message, {
          style: {
            backgroundColor: "green",
            color: "white",
          },
        });
        setFName("");
        setFPhone("");
        setDFetail("");
        setFDob("");
        setFGendar("");
        setFPlace("");
        setFTime("");
      } else {
        toast.error(data && data.result.message, {
          style: {
            backgroundColor: "red",
            color: "white",
          },
        });
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred. Please try again.", {
        style: {
          backgroundColor: "red",
          color: "white",
        },
      });
    }
  };

  const handleInquirySubmit = async (e) => {
    e.preventDefault();
    inqueryForm.append("Name", inquerynName);
    inqueryForm.append("Mobile", inquerynPhone);
    inqueryForm.append("Details", inquerynDetail);

    try {
      const response = await fetch(`${baseUrl}inquiryForm`, {
        method: "POST",
        body: inqueryForm,
      });

      const data = await response.json();
      if (data && data.message === "Your Inquiry Successfully Registered") {
        toast.success(data && data.message, {
          style: {
            backgroundColor: "green",
            color: "white",
          },
        });
        close_pupup.click();
        setInquerynName("");
        setInquerynPhone("");
        setInquerynDetail("");
      } else {
        toast.error(data && data.result.message, {
          style: {
            backgroundColor: "red",
            color: "white",
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // -----------> DATA GET <-------------

  const fetchData = async () => {
    try {
      const response = await fetch(`${baseUrl}Home`, {
        method: "POST",
      });

      const data = await response.json();
      if (data && data.message === "Fetched Successfully") {
        setHawanPoojanData(data && data.result.hawanpoojan);
        setRashiData(data && data.result.rashi);
        setRatnaData(data && data.result.ratna);
        setYantraData(data && data.result.yantra);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // -----------> DATA GET <-------------

  const getsliderData = async () => {
    const [data, consultData] = await Promise.all([
      viewData("getSlider"),
      viewData("ServiceDetail/marrige-problem"),
    ]);
    setSliderData(data && data.slider);
    setConsultData(consultData && consultData.relatedservice);
  };

  // -----------> API CALL ON PAGE LOAD <-------------
  useEffect(() => {
    // Scroll to the top of the page
    AOS.init({
      duration: 1200,
    });
    window.scrollTo(0, 0);
    getsliderData();
    fetchData();

    // Update document title
    document.title = 'Home - Vedic Astrology Consultation | Numerology | Palmistry | Vastu | Free Kundali Analysis - Vedic Astro Center';

    // Remove existing meta tags if any
    const existingMetaTags = document.querySelectorAll('meta[name="description"], meta[name="keywords"], meta[property^="og"]');
    existingMetaTags.forEach(tag => tag.remove());

    // Create new meta tag for description
    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = 'Welcome to Vedic Astro Center! Discover the best online astrologer consultation with free Kundali analysis, expert astrologer consultations, and guidance from scientific and Vedic astrology experts. Explore Numerology, Palmistry, Vastu, and more. Talk to our expert astrologers today!';
    document.head.appendChild(metaDescription);

    // Create meta tag for keywords
    const metaKeywords = document.createElement('meta');
    metaKeywords.name = 'keywords';
    metaKeywords.content = 'Free Jyotish, Jyotish in Hindi, Hindi Jyotish, Rashifal, Jyotish News, Vastu Shastra, Vastu Tips, free Kundli in Hindi, Astrology in Hindi, Yearly predictions in Hindi, Monthly predictions in Hindi, Weekly predictions in Hindi, Daily predictions in Hindi, Jyotish in hindi, Astrology, Free Horoscope, Indian Astrology, Free Future Prediction in Hindi, Bhavishyafal, Numerology, Vastu, Tarot Reading in Hindi, Palmistry in Hindi, Fengshui, Free Janampatri, free Kundali Matching, Arti, Chalisa, Vedic Mantra, Vrat Katha, Free Kundali Milan';
    document.head.appendChild(metaKeywords);

    // Create Open Graph meta tags for social sharing
    const ogTitle = document.createElement('meta');
    ogTitle.property = 'og:title';
    ogTitle.content = 'Home - Vedic Astrology Consultation | Numerology | Palmistry | Vastu | Free Kundali Analysis - Vedic Astro Center';
    document.head.appendChild(ogTitle);

    const ogDescription = document.createElement('meta');
    ogDescription.property = 'og:description';
    ogDescription.content = 'Welcome to Vedic Astro Center! Discover the best online astrologer consultation with free Kundali analysis, expert astrologer consultations, and guidance from scientific and Vedic astrology experts. Explore Numerology, Palmistry, Vastu, and more. Talk to our expert astrologers today!';
    document.head.appendChild(ogDescription);

    const ogType = document.createElement('meta');
    ogType.property = 'og:type';
    ogType.content = 'website';
    document.head.appendChild(ogType);

    const ogUrl = document.createElement('meta');
    ogUrl.property = 'og:url';
    ogUrl.content = 'https://vedicastrocenter.com/';
    document.head.appendChild(ogUrl);

    // Replace with your actual image URL
    const ogImage = document.createElement('meta');
    ogImage.property = 'og:image';
    // ogImage.content = `${baseUrlImage}assets/images/home.jpg`;
    document.head.appendChild(ogImage);

    const ogImageAlt = document.createElement('meta');
    ogImageAlt.property = 'og:image:alt';
    ogImageAlt.content = 'Home - Vedic Astrology Consultation';
    document.head.appendChild(ogImageAlt);

    // Clean up function to remove dynamically added meta tags on unmount (optional)
    return () => {
      document.title = 'Vedic Astrology Consultation | Numerology | Palmistry | Vastu | Free Kundali Analysis - Vedic Astro Center'; // Restore default title if needed
      existingMetaTags.forEach(tag => tag.remove());
    };
  }, []);
  return (

    <>
      <div className="wrapper">
        <section className="headerSlider">
          {/* <OwlCarousel className="bannerHeader" {...options}>
              {sliderData &&
                sliderData.map((val, index) => {
                  return (
                    <figure key={index}>
                      <img
                        src={`${baseUrlImage}uploads/slider/${val.image}`}
                        alt=""
                      />
                    </figure>
                  );
                })}
            </OwlCarousel> */}
          <div className="bannerHeader ">
            <figure>
              <img src="assets/images/slider3.png" alt="" />
            </figure>
          </div>
          <div className="container  headInq">
            <div className="row formInqCard">
              <div className="headerGrid">
                <div className="cardAstro">
                  <h1>
                    Welcome To
                    <span> Vedic Astro </span>
                  </h1>

                  <p>
                    वैदिक ज्योतिषप्राचीन वैदिक ज्योतिष की मदद से अपने जीवन के
                    हर पहलू को जानें और अपने भविष्य को उज्जवल बनाएं। आज ही
                    अपनी कुंडली का विश्लेषण करवाएं!
                  </p>
                  <Link to="tel:+919926653156">
                    <div className="numCard">
                      <figure>
                        <img src="assets/images/icons/call.svg" alt="" />
                      </figure>
                      <h6>+91 9926653156</h6>
                    </div>
                  </Link>
                </div>
                <div className="formInquiry">
                  <h5>REQUEST A CONSULTING</h5>
                  <p>
                    हम आपके करियर, विवाह, स्वास्थ्य, और शिक्षा से संबंधित प्रश्नों का सटीक समाधान प्रदान करते हैं। अनुभवी ज्योतिष आचार्य रवि शर्मा से परामर्श लें और अपने जीवन के महत्वपूर्ण फैसले सही दिशा में लें।
                  </p>
                  <div className="formCard">
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <input
                          type="text"
                          onChange={(e) => setName(e.target.value)}
                          className="form-control"
                          placeholder="Full Name"
                          name="name"
                          required
                          value={name}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          // onChange={(e)=>setPhone(e.target.value)}
                          onChange={handleChange}
                          maxLength={10}
                          className={`form-control ${isValid ? "" : "invalid"
                            }`}
                          placeholder="Phone No. (10 digits)"
                          name="phone"
                          required
                          value={phone}
                          pattern="\d*"
                          title={
                            isValid
                              ? ""
                              : "Phone number must be exactly 10 digits."
                          }
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="date"
                          onChange={(e) => setDob(e.target.value)}
                          className="form-control"
                          placeholder="Date od Brith"
                          name="name"
                          required
                          value={dob}
                          max={getCurrentDate()}
                        />
                      </div>
                      <div className="form-group">
                        <textarea
                          onChange={(e) => setDetail(e.target.value)}
                          placeholder="Message"
                          value={detail}
                        />
                      </div>
                      <div className="form-group">

                      </div>
                      <button
                        type="submit"
                        name="enquiry"
                        className="btn-started"
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* for mobile  */}
        {/* <section className="resp">
      <div className="container">
        <div className="row">
          <div className="formInquiry respInquiry">
            <h5>REQUEST A CONSULTING</h5>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. In
              voluptate eveniet ut dicta impedit alias quibusdam! Officiis
            </p>
            <div className="formCard">
              <form method="post">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Full Name"
                    name="name"
                    required=""
                    defaultValue=""
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Phone No."
                    name="name"
                    required=""
                    defaultValue=""
                  />
                </div>
                <div className="form-group">
                  <textarea placeholder="Message" defaultValue={""} />
                </div>
              </form>
              <button type="submit" name="enquiry" className="btn-started">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>*/}
        <section className="panditJi" data-aos="fade-up">
          <div className="container">
            <div className="aboutSec">
              <div className="row">
                <div className="col-md-4 bgWh">
                  <div className="pandiImg">
                    <figure>
                      <img src="assets/images/pt11.png" alt="" />
                    </figure>
                  </div>
                </div>
                <div className="col-md-8 ">
                  <div className="panditText">
                    <h3>Welcome To Vedic Astro Center</h3>
                    <p>
                      नमस्कार स्वागत है आपका Vedic Astro में वैदिक ज्योतिष को
                      परिभाषित किया जाए तो कहेंगे कि वैदिक ज्योतिष ऐसा विज्ञान
                      या शास्त्र है जो आकाश मंडल में विचरने वाले ग्रहों जैसे
                      सूर्य, चन्द्र, मंगल, बुध, गुरु, शुक्र, शनि, राहु, केतु,
                      के साथ राशियों एवं नक्षत्रों का अध्ययन करता है और इन
                      आकाशीय तत्वों से पृथ्वी एवं पृथ्वी पर रहने वाले लोग किस
                      प्रकार प्रभावित होते हैं उनका विश्लेषण करता है। वैदिक
                      ज्योतिष में गणना के क्रम में राशिचक्र, नवग्रह, जन्म राशि
                      को महत्वपूर्ण तत्व के रूप में देखा जाता है। अनादि काल से
                      ही ज्योतिष भविष्य जानने की मुख्य विद्या के रूप में जानी
                      जाती है।
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <FreeKundli /> */}

        <section className="kundalipaid">
          <div className="row">
            <div className="container">
              <div className="col-md-7">
                <div className="text_kundaliwraptext" data-aos="fade-right">
                  <h4>मुफ्त कुंडली विश्लेषण</h4>
                  <p>
                    जन्म कुंडली व्यक्ति के जीवन का मानचित्र होती है। इसमें
                    ग्रहों और नक्षत्रों की स्थिति का विवरण होता है। कुंडली के
                    माध्यम से आप अपने जीवन के विभिन्न पहलुओं का विश्लेषण कर
                    सकते हैं। यह आपको स्वास्थ्य, करियर, और व्यक्तिगत संबंधों
                    के बारे में महत्वपूर्ण जानकारी प्रदान करती है। कुंडली
                    विश्लेषण के लिए किसी विशेषज्ञ ज्योतिषी की सहायता लें।
                    हमारे द्वारा प्रदान किया गया मुफ्त कुंडली विश्लेषण आपकी
                    समस्याओं का समाधान खोजने में सहायक हो सकता है।
                  </p>
                </div>
              </div>
              <div className="col-md-5">
                <div
                  className="formInquiry popupInquiry"
                  data-aos="fade-left"
                >
                  <div className="formCard">
                    <form onSubmit={handleFSubmit}>
                      <div className="form-group">
                        <input
                          type="text"
                          onChange={(e) => setFName(e.target.value)}
                          className="form-control"
                          placeholder="Full Name"
                          name="name"
                          required
                          value={fname}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          onChange={handlefChange}
                          maxLength={10}
                          className={`form-control ${isfValid ? "" : "invalid"
                            }`}
                          placeholder="Phone No. (10 digits)"
                          name="phone"
                          required
                          value={fphone}
                          pattern="\d*"
                          title={
                            isfValid
                              ? ""
                              : "Phone number must be exactly 10 digits."
                          }
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="Date"
                          onChange={(e) => setFDob(e.target.value)}
                          className="form-control"
                          placeholder="Date of Birth"
                          name="dob"
                          required
                          value={fdob}
                          max={getCurrentDate()}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="time"
                          onChange={(e) => setFTime(e.target.value)}
                          className="form-control"
                          placeholder="Time (HH:MM)"
                          name="time"
                          required
                          value={ftime}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          onChange={(e) => setFPlace(e.target.value)}
                          className="form-control"
                          placeholder="Place"
                          name="place"
                          required
                          value={fplace}
                        />
                      </div>
                      <div className="form-group">
                        <select
                          onChange={(e) => setFGendar(e.target.value)}
                          className="form-control"
                          placeholder="Gender"
                          name="gender"
                          required
                          value={fgendar}
                        >
                          <option value="" disabled>
                            Select Gender
                          </option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </select>
                      </div>

                      <div className="form-group">
                        <textarea
                          onChange={(e) => setDFetail(e.target.value)}
                          placeholder="Message"
                          value={fdetail}
                        />
                      </div>

                      <button
                        type="submit"
                        name="enquiry"
                        className="btn-started"
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="consultUs" data-aos="zoom-in">
          <div className="container">
            <div className="row">
              <div className="myHeading">
                <h1>समाधान</h1>
              </div>
              <div className="gridConsult">
                {consultData &&
                  consultData.map((val, index) => {
                    return (
                      <div className="consultCard card1" key={index}>
                        <div className="imgCar">
                          <figure>
                            <img
                              src={`${baseUrlImage}uploads/category/${val.Photo}`}
                              alt=""
                            />
                          </figure>
                        </div>
                        <h4>{val.name}</h4>
                        <p
                          dangerouslySetInnerHTML={{ __html: val.Details }}
                        />
                        <div className="readMore">
                          <Link to={`/consult/${val.alias}`}>
                            {" "}
                            <figure>
                              <img
                                src="assets/images/icons/readMore.svg"
                                alt=""
                              />
                            </figure>
                          </Link>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="viewBtn">
                <Link to="/consult-us">View All</Link>
              </div>
            </div>
          </div>
        </section>
        {/*------------> OUR SERVICES SECTION <-----------  */}

        <section className="ourServices" data-aos="zoom-in-up">
          <div className="container">
            <div className="row">
              <div className="myHeading">
                <h2>पूजन</h2>
              </div>
              <div className="serviceGrid">
                {hawanPoojanData &&
                  hawanPoojanData.map((val, index) => {
                    return (
                      <div className="serviceCard" key={index}>
                        <figure>
                          <img
                            src={`${baseUrlImage}uploads/createpage/${val.Image}`}
                            alt=""
                          />
                        </figure>
                        <div className="serviceText">
                          <h5>{val.Title}</h5>
                          <p
                            dangerouslySetInnerHTML={{ __html: val.Detail }}
                          />
                          <Link to={`/hawanpoojan/${val.CreatePageAlias}`}>
                            Know More
                            <figure>
                              <img
                                src="assets/images/icons/enquiry.svg"
                                alt=""
                              />
                            </figure>
                          </Link>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="viewBtn">
                <Link to="/get/hawanpoojan">View All</Link>
              </div>
            </div>
          </div>
        </section>
        <section className="ofGod" data-aos="zoom-in-up">
          <div className="container">
            <div className="row">
              <div className="myHeading">
                <h2>वैदिक ज्योतिष परामर्श</h2>
              </div>
              <div className="ofGodText">
                <p>
                  यहां हम आपको वैदिक ज्योतिष की प्राचीन विद्या के माध्यम से
                  आपके जीवन को समझने और सुधारने में मदद करेंगे। ज्योतिषीय
                  परामर्श, कुंडली मिलान, और भविष्यवाणियों के साथ, हम आपके हर
                  सवाल का समाधान देने के लिए तत्पर हैं। यदि आप किसी भी तरह के
                  ज्योतिषीय परामर्श या कुंडली विश्लेषण की आवश्यकता महसूस कर
                  रहे हैं, तो निःशुल्क परामर्श के लिए हमसे संपर्क करें। आशा
                  है, यहां आपके अनुभव सुखद और ज्ञानवर्धक होंगे। 🙏
                </p>
              </div>
            </div>
          </div>
        </section>

        {/*------------> RASHI RATAN SECTION <-----------  */}

        <section className="rashiRatn" data-aos="zoom-in">
          <div className="container">
            <div className="row">
              <div className="myHeading">
                <h2>
                  राशि <span>रत्न</span>
                </h2>
              </div>
              <div className="ratnGrid">
                {ratnaData &&
                  ratnaData.map((val, index) => {
                    return (
                      <div className="ratnCard" key={index}>
                        <figure>
                          <img
                            src={`${baseUrlImage}uploads/createpage/${val.Image}`}
                            alt=""
                          />
                        </figure>
                        <div className="ratnText">
                          <h4>{val.Title}</h4>

                          <p
                            dangerouslySetInnerHTML={{ __html: val.Detail }}
                          />
                          <div className="rashiRead">
                            <Link
                              to="/inquiry"
                              data-toggle="modal"
                              data-target="#plan-detail"
                            >
                              Inquiry Now
                            </Link>
                            <Link to={`/ratna/${val.CreatePageAlias}`}>
                              Read More
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="viewBtn">
                <Link to="/get/ratna">View All</Link>
              </div>
            </div>
          </div>
        </section>

        {/*------------> ZODIAC SECTION <-----------  */}

        <section className="rashiPhal" data-aos="zoom-in-up">
          <div className="container">
            <div className="row">
              <div className="myHeading">
                <h2>अपनी राशि चुनें</h2>
              </div>
              <div className="rashiGrid">
                {rashiData &&
                  rashiData.map((val, index) => {
                    return (
                      <div className="rashiCard" key={index}>
                        <Link to={`/rashi/${val.CreatePageAlias}`}>
                          <figure>
                            {" "}
                            <img
                              src={`${baseUrlImage}uploads/createpage/${val.Image}`}
                              alt=""
                            />
                          </figure>
                          <strong>{val.Title}</strong>
                        </Link>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </section>

        {/*------------> YANTRA SECTION <-----------  */}

        <section className="yantra" data-aos="zoom-in">
          <div className="container">
            <div className="row">
              <div className="myHeading">
                <h2>यंत्र</h2>
              </div>
              <div className="yantraGrid">
                {yantraData &&
                  yantraData.map((val, index) => {
                    return (
                      <div className="yantraCard" key={index}>
                        <figure>
                          <img
                            src={`${baseUrlImage}uploads/createpage/${val.Image}`}
                            alt=""
                          />
                        </figure>
                        <div className="yantraText">
                          <h5>{val.Title}</h5>
                          <p
                            dangerouslySetInnerHTML={{ __html: val.Detail }}
                          />
                          <Link to={`/yantra/${val.CreatePageAlias}`}>
                            Know More
                            <figure>
                              <img
                                src="assets/images/icons/enquiry.svg"
                                alt=""
                              />
                            </figure>
                          </Link>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="viewBtn">
                <Link to="/get/yantra">View All</Link>
              </div>
            </div>
          </div>
        </section>


      </div>

      <div
        id="plan-detail"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="my-modal-title"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                className="close close_pupup"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">X</span>
              </button>
            </div>
            <div className="formInquiry popupInquiry">
              <h5>REQUEST A CONSULTING</h5>
              <p>
                आपकी समस्या का समाधान यहा उपलब्ध है |समस्या के समाधान के लिए
                हमसे संपर्क करे |
              </p>
              <div className="formCard">
                <form onSubmit={handleInquirySubmit}>
                  <div className="form-group">
                    <input
                      type="text"
                      onChange={(e) => setInquerynName(e.target.value)}
                      className="form-control"
                      placeholder="Full Name"
                      name="name"
                      required
                      value={inquerynName}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Phone No. (10 digits)"
                      name="phone"
                      required
                      value={inquerynPhone}
                      onChange={(e) => setInquerynPhone(e.target.value)}
                      minLength={10}
                      maxLength={13}
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      onChange={(e) => setInquerynDetail(e.target.value)}
                      placeholder="Message"
                      value={inquerynDetail}
                    />
                  </div>

                  <div className="form-group">

                  </div>

                  <button
                    type="submit"
                    name="enquiry"
                    className="btn-started"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={2000} />
    </>
    // </DocumentTitle>
  );
};

export default Home;
