import React, { useEffect } from "react";

const Privacy = () => {

  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);

    // Update document title
    document.title = 'Privacy Policy - Vedic Astro Center | Confidentiality and Data Protection';

    // Remove existing meta tags if any
    const existingMetaTags = document.querySelectorAll('meta[name="description"], meta[name="keywords"], meta[property^="og"]');
    existingMetaTags.forEach(tag => tag.remove());

    // Create new meta tag for description
    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = 'Read the Privacy Policy of Vedic Astro Center regarding confidentiality, data protection, and GDPR compliance. Learn how we safeguard your personal information and adhere to privacy regulations.';
    document.head.appendChild(metaDescription);

    // Create meta tag for keywords
    const metaKeywords = document.createElement('meta');
    metaKeywords.name = 'keywords';
    metaKeywords.content = 'privacy policy, data protection, personal information security, GDPR compliance, user data confidentiality, privacy statement, privacy regulations, data security measures';
    document.head.appendChild(metaKeywords);

    // Create Open Graph meta tags for social sharing
    const ogTitle = document.createElement('meta');
    ogTitle.property = 'og:title';
    ogTitle.content = 'Privacy Policy - Vedic Astro Center | Confidentiality and Data Protection';
    document.head.appendChild(ogTitle);

    const ogDescription = document.createElement('meta');
    ogDescription.property = 'og:description';
    ogDescription.content = 'Read the Privacy Policy of Vedic Astro Center regarding confidentiality, data protection, and GDPR compliance. Learn how we safeguard your personal information and adhere to privacy regulations.';
    document.head.appendChild(ogDescription);

    const ogType = document.createElement('meta');
    ogType.property = 'og:type';
    ogType.content = 'website';
    document.head.appendChild(ogType);

    const ogUrl = document.createElement('meta');
    ogUrl.property = 'og:url';
    ogUrl.content = 'https://vedicastrocenter.com/privacy';
    document.head.appendChild(ogUrl);

    // Replace with your actual image URL
    const ogImage = document.createElement('meta');
    ogImage.property = 'og:image';
    // ogImage.content = `${baseUrlImage}assets/images/privacy-policy.jpg`;
    document.head.appendChild(ogImage);

    const ogImageAlt = document.createElement('meta');
    ogImageAlt.property = 'og:image:alt';
    ogImageAlt.content = 'Privacy Policy - Vedic Astro Center';
    document.head.appendChild(ogImageAlt);
  }, []);
  return (
    <>
      <section className="breadCreamp">
        <div className="container">
          <div className="breadTitle">
            <h1> Privacy Policy</h1>
          </div>
        </div>
      </section>
      <section
        className="kundalipaid kundalipaidkundalipge"
        data-aos="zoom-in-up"
      >
        <div className="row">
          <div className="container">
            <div className="col-md-12">
              <div className="text_kundaliwraptext">
                <h4>मुफ्त कुंडली विश्लेषण</h4>
                <br />
                <p>
                  नमस्कार स्वागत है आपका Vedic Astro की गोपनीयता नीति पृष्ठ पर।
                  हमारे वेबसाइट का स्वागत है। हमारी गोपनीयता नीति निम्नलिखित
                  बयान करती है कि हम आपकी व्यक्तिगत जानकारी का सम्मान करते हैं
                  और इसे कैसे उपयोग करते हैं।
                  <br />
                  <br />
                  <b>हमारी गोपनीयता नीति कवर करती है:</b>
                  <br />
                  <br />
                  1. संकलित जानकारी: हम आपकी व्यक्तिगत जानकारी को उनकी सुरक्षा
                  सुनिश्चित करते हैं। हम आपकी जन्मतिथि, जन्मस्थान और अन्य
                  व्यक्तिगत जानकारी को केवल ज्योतिषीय सेवाओं के लिए उपयोग करते
                  हैं।
                  <br />
                  <br />
                  2. डेटा की सुरक्षा: हम आपकी जानकारी की सुरक्षा के लिए उच्च
                  स्तर की सुरक्षा प्रदान करते हैं। हम डेटा लॉस या अनधिकृत उपयोग
                  से बचाने के लिए उपाय करते हैं।
                  <br />
                  <br />
                  3. कुकीज़: हम अपनी वेबसाइट के लिए कुकीज़ का उपयोग करते हैं
                  ताकि हम आपको बेहतर अनुभव प्रदान कर सकें। इन कुकीज़ को हम आपके
                  पहचानने योग्य जानकारी के लिए उपयोग नहीं करते हैं।
                  <br />
                  <br />
                  4. अपडेट: हम अपनी गोपनीयता नीति को समय-समय पर अपडेट कर सकते
                  हैं। नई नीतियों के बारे में जानकारी प्राप्त करने के लिए कृपया
                  इस पृष्ठ को नियमित रूप से देखें।
                  <br />
                  <br />
                  संपर्क करें:
                  <br />
                  <br />
                  यदि आपके पास हमारी गोपनीयता नीति के बारे में कोई सवाल है, तो
                  कृपया हमसे संपर्क करें:-
                  <br />
                  <br />
                  ईमेल: <span><a href="mailto:info@vedicastrocenter.com">info@vedicastrocenter.com </a></span>
                  <br />
                  फोन :  <span><a href="tel:+919926653156">+91 9926653156</a></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Privacy;
