import React, { useEffect } from "react";
import DocumentTitle from "react-document-title";
import Consult from "../services/Consult";

const Allservice = () => {

  useEffect(()=>{
    window.scroll(0,0);
  },[])
  return (
  
    // <DocumentTitle title="Our services | Vedic Astro">
    <>
    <section className="breadCreamp">
    <div className="container">
      <div className="breadTitle">
        <h2>हमारी सेवाएँ</h2>
      <p>
      वेदिक एस्ट्रो सेवा में हम आपको पारंपरिक वैदिक ज्योतिषीय सेवाएं प्रदान करते हैं, जिनमें कालसर्प दोष पूजा, वास्तु पूजा, कुंडली विश्लेषण, राशिफल और अन्य सेवाएं शामिल हैं। हमारे अनुभवी ज्योतिषाचार्य रवि शर्मा आपकी समस्याओं का समाधान करके आपको सुखी और सफल जीवन जीने में मदद करेंगे। हमारे साथ जुड़कर अपने भविष्य को उज्ज्वल बनाएं।
      </p>
      </div>
    </div>
  </section>
<Consult/>
</>
    // </DocumentTitle>

  );
};

export default Allservice;
