import React, { useState, useEffect } from "react";
import { baseUrlImage } from "../../index";
import { viewData } from "../../apiservice/ApiServices";
import { Link } from "react-router-dom";

const Gallery = () => {
  const [galleryData, setGalleryData] = useState([]);

  const fetchData = async () => {
    try {
      const data = await viewData("getGallery");
      setGalleryData(data && data.gallery);
    } catch (error) {}
  };

  const handleImageClick = (val) => {
    // Logic to handle image click
    console.log('Clicked image:', val);
  };
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
    fetchData();
    // Update document title
    document.title = 'Astrology Gallery - Vedic Astrology Images, Rituals, and Events | Vedic Astro Center';

    // Remove existing meta tags if any
    const existingMetaTags = document.querySelectorAll('meta[name="description"], meta[name="keywords"], meta[property^="og"]');
    existingMetaTags.forEach(tag => tag.remove());

    // Create new meta tag for description
    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = 'Explore the Astrology Gallery at Vedic Astro Center featuring images, rituals, and events related to Vedic astrology. Discover visual insights into astrology practices, ceremonies, and cultural aspects depicted through our curated collection.';
    document.head.appendChild(metaDescription);

    // Create meta tag for keywords
    const metaKeywords = document.createElement('meta');
    metaKeywords.name = 'keywords';
    metaKeywords.content = 'Vedic astrology images, astrology rituals, astrology events, astrological ceremonies, astrology photos, Vedic rituals, astrological practices, astrology symbolism, astrology celebrations, astrology culture';
    document.head.appendChild(metaKeywords);

    // Create Open Graph meta tags for social sharing
    const ogTitle = document.createElement('meta');
    ogTitle.property = 'og:title';
    ogTitle.content = 'Astrology Gallery - Vedic Astrology Images, Rituals, and Events | Vedic Astro Center';
    document.head.appendChild(ogTitle);

    const ogDescription = document.createElement('meta');
    ogDescription.property = 'og:description';
    ogDescription.content = 'Explore the Astrology Gallery at Vedic Astro Center featuring images, rituals, and events related to Vedic astrology. Discover visual insights into astrology practices, ceremonies, and cultural aspects depicted through our curated collection.';
    document.head.appendChild(ogDescription);

    const ogType = document.createElement('meta');
    ogType.property = 'og:type';
    ogType.content = 'website';
    document.head.appendChild(ogType);

    const ogUrl = document.createElement('meta');
    ogUrl.property = 'og:url';
    ogUrl.content = 'https://vedicastrocenter.com/gallery';
    document.head.appendChild(ogUrl);

    // Replace with your actual image URL
    const ogImage = document.createElement('meta');
    ogImage.property = 'og:image';
    // ogImage.content = `${baseUrlImage}assets/images/gallery.jpg`;
    document.head.appendChild(ogImage);

    const ogImageAlt = document.createElement('meta');
    ogImageAlt.property = 'og:image:alt';
    ogImageAlt.content = 'Astrology Gallery - Vedic Astrology Images, Rituals, and Events';
    document.head.appendChild(ogImageAlt);
  }, []);

  return (
    <>
      <section className="breadCreamp">
        <div className="container">
          <div className="breadTitle">
            <h1>Gallery</h1>
          </div>
        </div>
      </section>
      <section class="portfolio-section" id="portfolio"  data-aos="zoom-in">
        <div class="container-fluid">
          <div class="row">
            {/* <div class="col-lg-12 text-center">
        </div> */}
          </div>
          <div class="portfolio-menu mt-2 mb-4"></div>
          <ul class="row portfolio-item">
            {galleryData &&
              galleryData.map((val, index) => {
                return (
                  <li
                  key={index}
                   className="mix web col-xl-3 col-md-4 col-12 col-sm-6 pd">
                    <img
                      src={`${baseUrlImage}uploads/gallery/${val.Image}`}
                      alt="" />
                   
                    <div className="portfolio-overlay">
                      <div className="overlay-content">
                        <p className="category">{val.Name}</p>
                        <Link to="#" title="View Project" target="_blank">
                          <div className="magnify-icon">
                            <p>
                              <span>
                                <i class="fa fa-link" aria-hidden="true"></i>
                              </span>
                            </p>
                          </div>
                        </Link>
                        <Link
                          data-fancybox="item"
                          title={val.Name}
                          data-src={`${baseUrlImage}uploads/gallery/${val.Image}`}
                          data-size="1200x600">
                         <div className="magnify-icon" onClick={() => handleImageClick(val)}>
                            <p>
                              <span>
                                <i
                                  className="fa fa-search"
                                  aria-hidden="true"></i>
                              </span>
                            </p>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </section>
    </>
  );
};

export default Gallery;
