import React, { useEffect, useState } from "react";
import { viewData } from "../../apiservice/ApiServices";
import { IoMdCall } from "react-icons/io";
import { Link } from "react-router-dom";

const Header = () => {
  const [consultData, setConsultData] = useState([]);
  const [serviceData, setServiceData] = useState([]);

  const fetchData = async () => {
    const [consultData, serviceData] = await Promise.all([
      viewData("getConsult"),
      viewData("getService"),
    ]);
    setConsultData(consultData && consultData.Consult);
    setServiceData(serviceData && serviceData.menu);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <nav className="navbar ">
        <div className="topBar">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="topBarContent ">
                  <ul className="topBarUl">
                    <li>
                      <span><a href="mailto:info@vedicastrocenter.com">info@vedicastrocenter.com</a></span>
                    </li>
                  </ul>
                  <ul className="topBarUl">
                    <li>
                      <Link to="tel:+919926653156">
                        फ्री परामर्श हेतु कॉल करें: <IoMdCall />  <span><a href="tel:+919926653156">+91 9926653156</a></span>
                      </Link>
                    </li>
                  </ul>
                  <ul className="social">
                    <li>Follow Us:</li>
                    <li>
                      <figure>
                        <Link
                          to="https://www.facebook.com/vedicastrors"
                          target="_blank"
                        >
                          <img src="assets/images/icons/facebook.png" alt="" />
                        </Link>
                      </figure>
                    </li>
                    <li>
                      <figure>
                        <Link
                          to="https://www.instagram.com/vedic_astro_rs"
                          target="_blank"
                        >
                          <img src="assets/images/icons/insta.png" alt="" />
                        </Link>
                      </figure>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <header className="myHeader">
        <div className="container ">
          <div className="mainHeader">
            <div className="logo">
              <figure>
                <Link to="/">
                  <img src="../assets/images/icons/Group23677.svg" />
                </Link>
              </figure>
            </div>
            <nav className="myNav">
              <ul className="menu1">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link className="btnEnuiry" to="/freekundli">
                    Free Kundli
                  </Link>
                </li>
                <li className="SubMenu">
                  <Link to="/consult-us">Consultant</Link>
                  <ul className="megaMenu">
                    {consultData &&
                      consultData.map((val, index) => {
                        return (
                          <li className="subMe" key={index}>
                            <Link to={`/consult/${val.alias}`}>
                              {val.name}
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                </li>
                <li className="SubMenu">
                  {console.log(serviceData)}
                  <Link to="#">Service</Link>
                  <ul className="megaMenu">
                    {serviceData &&
                      serviceData.map((val, index) => {
                        return (
                          <li className="subMe" key={index}>
                            <Link to={`/get/${val.MenuAlias}`}>
                              {val.Menu}
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                </li>

                <li>
                  <Link className="btnEnuiry" to="/gallery">
                    Gallery
                  </Link>
                </li>
                <li>
                  <Link className="btnEnuiry" to="/inquiry">
                    Inquiry
                  </Link>
                </li>
                <li>
                  <Link to="/about-us">About Us</Link>
                </li>
                <div className="closeMe">
                  <button className="hideMe">
                    <img src="../assets/images/icons/close.svg" alt="" />
                  </button>
                </div>
              </ul>
            </nav>
            <div className="openMenu">
              <img src="../assets/images/icons/ba.svg" alt="" />
            </div>
          </div>
        </div>
      </header>
      <div className="whts_icon_wrapper wrapper_fixedtop">
        <div className="whts_icon_inner">
          <Link
            to="https://api.whatsapp.com/send?phone=919926653156"
            target="_blank"
          >
            <img src="../assets/images/icons/Group.svg" alt="whatsapp icon" />
            &nbsp; संपर्क करें
          </Link>
        </div>
      </div>
      <div className="whts_icon_wrapper">
        <div className="whts_icon_inner">
          {/* <Link target="_blank" to="/freekundli">
            <img src="assets/images/icons/Group-1.svg" alt="kundli" />
            मुफ्त कुंडली विश्लेषण ?
          </Link> */}
          <Link to="/freekundli" target="_blank">
            <img src="../assets/images/icons/Group-1.svg" alt="kundli" />
            &nbsp; मुफ्त कुंडली विश्लेषण ?
          </Link>
        </div>
      </div>
    </>
  );
};

export default Header;
