import { Route, Routes } from "react-router-dom";
import Home from "./component/Home/Home";
import Header from "./component/Layout/Header";
import Footer from "./component/Layout/Footer";
import Inquiry from "./component/Inquiry/Inquiry";
import Allservice from "./component/Pages/Allservice";
import ConsultUs from "./component/Pages/ConsultUs";
import AboutUs from "./component/Pages/AboutUs";
import RashiDetails from "./component/Pages/RashiDetails";
import HawanPuja from "./component/Pages/HawanPuja";
import ViewAll from "./component/Pages/ViewAll";
import ServiceDetails from "./component/Pages/ServiceDetail";
import RatnaDetail from "./component/Pages/RatnaDetail";
import YantraDetail from "./component/Pages/YantraDetail";
import Rudrakshdetail from "./component/Pages/Rudrakshdetail";
import Gallery from "./component/Pages/Gallery";
import PageNotFound from "./component/Pages/PageNotFound";
import FreeKundli from "./component/Pages/FreeKundli";
import { Helmet } from "react-helmet";
import Privacy from "./component/Pages/Privacy";

function App() {
  return (
    <>
      <Header />
      <Helmet>
         <meta
          name="description"
          content="Discover the wisdom of Vedic astrology with Vedic Astro Center. Our expert astrologers offer personalized readings, horoscope analysis, birth chart interpretations, and effective astrological remedies. Unlock the secrets of your destiny and find guidance in your personal and professional life through authentic Vedic astrology. Explore our services today and take a step towards a brighter future."
        />
        <meta
          name="keywords"
          content="Vedic astrology, astrology readings, horoscope, birth chart, kundli, Vedic astrologer, astrological consultation, astrological remedies, Vedic Astro Center, jyotish, Vedic predictions"
        />
      </Helmet>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="*" element={<PageNotFound />} />
        <Route exact path="/inquiry" element={<Inquiry />} />
        <Route exact path="/our-services" element={<Allservice />} />
        <Route exact path="/consult-us" element={<ConsultUs />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/rashi/:id" element={<RashiDetails />} />
        <Route exact path="/hawanpoojan/:id" element={<HawanPuja />} />
        <Route exact path="/get/:id" element={<ViewAll />} />
        <Route exact path="/consult/:id" element={<ServiceDetails />} />
        <Route exact path="/ratna/:id" element={<RatnaDetail />} />
        <Route exact path="/yantra/:id" element={<YantraDetail />} />
        <Route exact path="/rudraksha/:id" element={<Rudrakshdetail />} />
        <Route exact path="/gallery" element={<Gallery />} />
        <Route exact path="/freekundli" element={<FreeKundli />} />
        <Route exact path="/privacy" element={<Privacy />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
