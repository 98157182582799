import React, { useEffect, useState } from "react";
import { viewData } from "../../apiservice/ApiServices";
import { Link } from "react-router-dom";

const Footer = () => {
  const [consultData, setConsultData] = useState([]);
  const [data, setData] = useState([]);

  const getsliderData = async () => {
    const [consultData] = await Promise.all([
      viewData("ServiceDetail/marrige-problem"),
    ]);

    setConsultData(consultData && consultData.relatedservice);
  };

  const fetchData = async (id) => {
    const data = await viewData(`getService`);
    setData(data && data.menu);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getsliderData();
    fetchData();
  }, []);
  // console.log(data);
  return (
    <>
      <section className="myFooter">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="footerLogo">
                <Link to="/">Vedic Astro</Link>

                <p>
                  पंडित रवि शर्मा के वैदिक ज्योतिष वेबसाइट पर आपका स्वागत है।
                  यहाँ पर पंडित जी कुंडली मिलान, काल सर्प दोष, विवाह दोष, मंगल
                  दोष और अन्य पूजा विधियों के माध्यम से आपकी समस्याओं का समाधान
                  करते हैं। पंडित जी की विद्वता और अनुभव आपके जीवन में सुख,
                  शांति और समृद्धि लाने में सहायक है। आप भी अपनी समस्याओं का
                  समाधान पंडित रवि शर्मा से प्राप्त करें और अपने जीवन को सुखमय
                  बनाएं।
                </p>
              </div>
            </div>
            <div className="col-md-8">
              <div className="gridGooter">
                <div className="footerCard">
                  <h5>समाधान</h5>
                  <ul>
                    {consultData &&
                      consultData.map((val, index) => {
                        return (
                          <li key={index}>
                            <Link to={`/consult/${val.alias}`}>
                              {val.name}
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                </div>
                <div className="footerCard">
                  <h5>सेवाएं</h5>
                  <ul>
                    {data &&
                      data.map((val, index) => {
                        return (
                          <li key={index}>
                            <Link to={`/get/${val.MenuAlias}`}>
                              {val.Menu}
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                </div>
                <div className="footerCard">
                  <h5>Quick Links</h5>
                  <ul>
                    <li>
                      <Link to="/about-us">About Us</Link>
                    </li>
                    <li>
                      <Link to="/consult-us">Consult Us</Link>
                    </li>
                    <li>
                      <Link to="/gallery">Gallery</Link>
                    </li>
                    <li>
                      <Link to="/inquiry">Inquiry</Link>
                    </li>
                    <li>
                      <Link to="/privacy"> Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="subFooter">
            <strong>
              © Copyright 2024. All Rights Reserved By :
              <Link target="_blank" to="https://vedicastrocenter.com">VedicAstro.com</Link>
              <br />
              Developed By &nbsp;
              <Link target="_blank" to="https://www.netspacesoftware.in/">
                Netspace Software Solutions Pvt Ltd
              </Link>
            </strong>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
