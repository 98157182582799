import { baseUrl } from "../index";

export const viewData = async (path) => {
    try {
      const response = await fetch(`${baseUrl}${path}`, {
        method: "POST",
      });

      const data = await response.json();
      if (data && data.message === "Fetched Successfully") {
        return data && data.result
      }
    } catch (error) {
      console.error(error);
    }
  };