import React from "react";
// import DocumentTitle from "react-document-title";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "../../index";
import { Helmet } from "react-helmet";

const Inquiry = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [detail, setDetail] = useState("");
  const [isValid, setIsValid] = useState(true);

  const validatePhoneNumber = (value) => {
    const phoneRegex = /^\d{10}$/;
    setIsValid(phoneRegex.test(value));
  };

  const handleChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setPhone(value);
    validatePhoneNumber(value);
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("Name", name);
    formData.append("Mobile", phone);
    formData.append("Details", detail);

    try {
      const response = await fetch(`${baseUrl}inquiryForm`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      if (data && data.message === "Your Inquiry Successfully Registered") {
        toast.success(data.message, {
          style: {
            backgroundColor: "green",
            color: "white",
          },
        });
        setName("");
        setPhone("");
        setDetail("");
      } else {
        toast.error(data && data.result.message, {
          style: {
            backgroundColor: "red",
            color: "white",
          },
        });
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred. Please try again.", {
        style: {
          backgroundColor: "red",
          color: "white",
        },
      });
    }
  };

  useEffect(() => {
    // Update document title
    document.title = 'ज्योतिषीय समस्या समाधान | संपर्क करें 9926653156';

    // Remove existing meta tags if any (optional based on your needs)
    const existingMetaTags = document.querySelectorAll('meta[name="description"], meta[name="keywords"], meta[property^="og:"]');
    existingMetaTags.forEach(tag => tag.remove());

    // Create new meta tag for description
    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = 'क्या आप ज्योतिषीय समस्याओं के समाधान की तलाश में हैं? Vedic Astro Center के INquiry पृष्ठ पर अपनी समस्या दर्ज करें और पाएँ विशेषज्ञ सलाह और समाधान। हमारे अनुभवी ज्योतिषियों से परामर्श के लिए अभी संपर्क करें 9926653156।';
    document.head.appendChild(metaDescription);

    // Create meta tag for keywords (optional)
    const metaKeywords = document.createElement('meta');
    metaKeywords.name = 'keywords';
    metaKeywords.content = 'INquiry, ज्योतिषीय INquiry, ज्योतिष समस्या समाधान, ज्योतिष परामर्श, वैदिक ज्योतिष, संपर्क करें, ज्योतिष सेवा, ज्योतिष विशेषज्ञ';
    document.head.appendChild(metaKeywords);

    // Create Open Graph meta tags for social sharing
    const ogTitle = document.createElement('meta');
    ogTitle.property = 'og:title';
    ogTitle.content = 'ज्योतिषीय समस्या समाधान - Vedic Astro | संपर्क करें 9926653156';
    document.head.appendChild(ogTitle);
  },[]);

  return (
    // <DocumentTitle title="हमसे संपर्क करें | वेदिक एस्ट्रो सेंटर - विशेषज्ञ वेदिक ज्योतिषियों से जुड़ें | Vedic astro">
    <>
      <Helmet>
        <title>
          {" "}
          हमसे संपर्क करें | वेदिक एस्ट्रो सेंटर - विशेषज्ञ वेदिक ज्योतिषियों से
          जुड़ें
        </title>
        <meta
          name="description"
          content="Get in touch with the experts at Vedic Astro Center for personalized astrology consultations and services. Whether you have questions about your horoscope, need a detailed birth chart analysis, or seek astrological remedies, our experienced Vedic astrologers are here to help. Contact us today to schedule a consultation and start your journey towards clarity and insight"
        />
        <meta
          name="keywords"
          content="वेदिक एस्ट्रो सेंटर संपर्क, ज्योतिष परामर्श, वेदिक ज्योतिषी संपर्क, ज्योतिष सेवाएं, कुंडली परामर्श, जन्म कुंडली विश्लेषण, ज्योतिषीय सलाह, contact Vedic Astro Center, astrology consultation, Vedic astrologer contact, astrology services, horoscope consultation, birth chart analysis, kundli reading, astrological advice"
        />
      </Helmet>

      <section className="breadCreamp">
        <div className="container">
          <div className="breadTitle">
            <h1>Inquiry</h1>
          </div>
        </div>
      </section>

      <div className="modal-dialog" role="document" data-aos="zoom-in-up">
        <div className="modal-content">
          <div className="modal-header">
            <button
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="formInquiry popupInquiry">
            <h5>REQUEST A CONSULTING</h5>
            <p>
            वेदिक एस्ट्रो ज्योतिष सेवा में आपका स्वागत है! यहाँ, हम आपके जीवन के विभिन्न पहलुओं के बारे में सटीक और विस्तृत जानकारी प्रदान करते हैं। चाहे आप अपने करियर, विवाह, स्वास्थ्य, या शिक्षा से संबंधित प्रश्नों का समाधान चाहते हों, हमारी अनुभवी ज्योतिषी टीम आपके साथ है।
            ज्योतिष आचार्य रवि शर्मा से परामर्श लें और अपने जीवन के महत्वपूर्ण फैसले सही दिशा में लें।
            </p>
            <div className="formCard">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    onChange={(e) => setName(e.target.value)}
                    className="form-control"
                    placeholder="Full Name"
                    name="name"
                    required
                    value={name}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    onChange={handleChange}
                    maxLength={10}
                    className={`form-control ${isValid ? "" : "invalid"}`}
                    placeholder="Phone No. (10 digits)"
                    name="phone"
                    required
                    value={phone}
                    pattern="\d*"
                    title={
                      isValid ? "" : "Phone number must be exactly 10 digits."
                    }
                  />
                </div>
                <div className="form-group">
                  <textarea
                    onChange={(e) => setDetail(e.target.value)}
                    placeholder="Message"
                    value={detail}
                  />
                </div>
                <div className="form-group"></div>
                <button type="submit" name="enquiry" className="btn-started">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={2000} />
    </>
    // </DocumentTitle>
  );
};

export default Inquiry;
