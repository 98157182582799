import React, { useEffect } from "react";
//import DocumentTitle from "react-document-title";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    // Update document title
    document.title = 'हमारे बारे में - Vedic Astro Center | ज्योतिष सलाह और समाधान';

    // Remove existing meta tags if any
    const existingMetaTags = document.querySelectorAll('meta[name="description"], meta[name="keywords"], meta[property^="og"]');
    existingMetaTags.forEach(tag => tag.remove());

    // Create new meta tag for description
    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = 'जानें Vedic Astro Center के बारे में - हमारा मिशन, हमारे विशेषज्ञ, और हमारी सेवाएँ। हमारे बारे में और हमारी विशेषता को समझें और ज्योतिष सलाह और समाधान के लिए हमसे जुड़ें।';
    document.head.appendChild(metaDescription);

    // Create meta tag for keywords
    const metaKeywords = document.createElement('meta');
    metaKeywords.name = 'keywords';
    metaKeywords.content = 'बारे में, वेदिक एस्ट्रो सेंटर, ज्योतिष सलाह, ज्योतिष समाधान, वैदिक ज्योतिष, ज्योतिषीय केंद्र, ज्योतिषीय परिचय';
    document.head.appendChild(metaKeywords);

    // Create Open Graph meta tags for social sharing
    const ogTitle = document.createElement('meta');
    ogTitle.property = 'og:title';
    ogTitle.content = 'हमारे बारे में - Vedic Astro Center | ज्योतिष सलाह और समाधान';
    document.head.appendChild(ogTitle);

    const ogDescription = document.createElement('meta');
    ogDescription.property = 'og:description';
    ogDescription.content = 'जानें Vedic Astro Center के बारे में - हमारा मिशन, हमारे विशेषज्ञ, और हमारी सेवाएँ। हमारे बारे में और हमारी विशेषता को समझें और ज्योतिष सलाह और समाधान के लिए हमसे जुड़ें।';
    document.head.appendChild(ogDescription);

    const ogType = document.createElement('meta');
    ogType.property = 'og:type';
    ogType.content = 'website';
    document.head.appendChild(ogType);

    const ogUrl = document.createElement('meta');
    ogUrl.property = 'og:url';
    ogUrl.content = 'https://vedicastrocenter.com/about-us';
    document.head.appendChild(ogUrl);

    // Note: Replace with your actual image URL
    const ogImage = document.createElement('meta');
    ogImage.property = 'og:image';
    // ogImage.content = `${baseUrlImage}assets/images/about-us.jpg`;
    document.head.appendChild(ogImage);

    const ogImageAlt = document.createElement('meta');
    ogImageAlt.property = 'og:image:alt';
    ogImageAlt.content = 'हमारे बारे में';
    document.head.appendChild(ogImageAlt);

  }, []);

  return (
    // <DocumentTitle title="हमारे बारे में | वेदिक एस्ट्रो सेंटर - प्राचीन वैदिक ज्योतिष की अद्भुत दुनिया | Vedic Astro">
    <>
      <Helmet>
        <title>हमारे बारे में | वेदिक एस्ट्रो सेंटर - प्राचीन वैदिक ज्योतिष की अद्भुत दुनिया</title>
        <meta name="description" content="नमस्कार, स्वागत है आपका Vedic Astro में। वैदिक ज्योतिष ऐसा विज्ञान या शास्त्र है जो आकाश मंडल में विचरने वाले ग्रहों जैसे सूर्य, चन्द्र, मंगल, बुध, गुरु, शुक्र, शनि, राहु, केतु, के साथ राशियों एवं नक्षत्रों का अध्ययन करता है और इन आकाशीय तत्वों से पृथ्वी एवं पृथ्वी पर रहने वाले लोग किस प्रकार प्रभावित होते हैं उनका विश्लेषण करता है। वैदिक ज्योतिष में गणना के क्रम में राशिचक्र, नवग्रह, जन्म राशि को महत्वपूर्ण तत्व के रूप में देखा जाता है। अनादि काल से ही ज्योतिष भविष्य जानने की मुख्य विद्या के रूप में जानी जाती है। वेदिक एस्ट्रो सेंटर में, हम इसी प्राचीन ज्ञान का उपयोग कर आपके जीवन में स्पष्टता और अंतर्दृष्टि लाने का प्रयास करते हैं।" />
        <meta name="keywords" content="वेदिक एस्ट्रो सेंटर के बारे में, वैदिक ज्योतिष, ज्योतिष विज्ञान, ग्रहों का अध्ययन, राशिचक्र, नवग्रह, जन्म राशि, भविष्यवाणी, ज्योतिषीय विश्लेषण, वैदिक ज्योतिष केंद्र" />
      </Helmet>
      <section className="breadCreamp">
        <div className="container">
          <div className="breadTitle">
            <h1>About us</h1>
          </div>
        </div>
      </section>
      <section className="panditJi" data-aos="zoom-in">
        <div className="container">
          <div className="aboutSec">
            <div className="row">
              <div className="col-md-4 bgWh">
                <div className="pandiImg">
                  <figure>
                    <img src="assets/images/pt4.png" alt="" />
                  </figure>
                </div>
              </div>
              <div className="col-md-8 ">
                <div className="panditText">
                  <h3>Welcome To Vedic Astro Center</h3>
                  <p>
                    नमस्कार स्वागत है आपका Vedic Astro में वैदिक ज्योतिष को
                    परिभाषित किया जाए तो कहेंगे कि वैदिक ज्योतिष ऐसा विज्ञान
                    या शास्त्र है जो आकाश मंडल में विचरने वाले ग्रहों जैसे
                    सूर्य, चन्द्र, मंगल, बुध, गुरु, शुक्र, शनि, राहु, केतु, के
                    साथ राशियों एवं नक्षत्रों का अध्ययन करता है और इन आकाशीय
                    तत्वों से पृथ्वी एवं पृथ्वी पर रहने वाले लोग किस प्रकार
                    प्रभावित होते हैं उनका विश्लेषण करता है। वैदिक ज्योतिष में
                    गणना के क्रम में राशिचक्र, नवग्रह, जन्म राशि को महत्वपूर्ण
                    तत्व के रूप में देखा जाता है। अनादि काल से ही ज्योतिष
                    भविष्य जानने की मुख्य विद्या के रूप में जानी जाती है।
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ofGod">
        <div className="container">
          <div className="row">
            <div className="myHeading">
              <h2>वैदिक ज्योतिष परामर्श</h2>
            </div>
            <div className="ofGodText">
              <p>
                नमस्कार स्वागत है आपका Vedic Astro में वैदिक ज्योतिष को
                यहां हम आपको वैदिक ज्योतिष की प्राचीन विद्या के माध्यम से आपके जीवन को समझने और सुधारने में मदद करेंगे। ज्योतिषीय परामर्श, कुंडली मिलान, और भविष्यवाणियों के साथ, हम आपके हर सवाल का समाधान देने के लिए तत्पर हैं। यदि आप किसी भी तरह के ज्योतिषीय परामर्श या कुंडली विश्लेषण की आवश्यकता महसूस कर रहे हैं, तो निःशुल्क परामर्श के लिए हमसे संपर्क करें। आशा है, यहां आपके अनुभव सुखद और ज्ञानवर्धक होंगे। 🙏
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
    // </DocumentTitle>
  );
};

export default AboutUs;
