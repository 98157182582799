import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { BrowserRouter} from 'react-router-dom';

import { BrowserRouter as Router } from 'react-router-dom';

export const baseUrl = "https://api.vedicastrocenter.com/api/";
export const baseUrlImage = "https://api.vedicastrocenter.com/";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <Router>
  <App />
  </Router>
  </React.StrictMode>
);

reportWebVitals();
