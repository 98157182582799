import React, { useEffect, useState } from "react";
import Consult from "../services/Consult";
import { viewData } from "../../apiservice/ApiServices";
import { useParams } from "react-router-dom";
import { baseUrlImage } from "../../index";
import DocumentTitle from "react-document-title";

const ServiceDetails = () => {
  const [data, setData] = useState([]);

  const { id } = useParams();

  const fetchData = async (id) => {
    const data = await viewData(`ServiceDetail/${id}`);
    setData(data && data.servicedetail[0]);
  };
  
  useEffect(() => {
    document.title = data.MetaTitle
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.content = (data.MetaDescription);
    } else {
      const meta = document.createElement('meta');
      meta.name = 'description';
      meta.content = "वैदिक ज्योतिष केंद्र में वैदिक ज्योतिष की ज्ञान संपदा का अन्वेषण करें। विशेषज्ञ ज्योतिषीय मार्गदर्शन, सटीक जन्म कुंडली विश्लेषण और व्यक्तिगत राशिफल भविष्यवाणियां प्राप्त करें। हमारी व्यापक ज्योतिष सेवाओं के साथ अपने भाग्य को खोलें।" ;
      document.head.appendChild(meta);
    }
  }, [data]);

  useEffect(() => {
          window.scrollTo(0, 0);
          fetchData(id);
  }, [id]);

  return (
  
    // <DocumentTitle title={`${data.name} | Vedic Astro`}>
        <>
      <section className="breadCreamp">
        <div className="container">
          <div className="breadTitle">
            <h1>{data.name}</h1>
           
          </div>
        </div>
      </section>
      <section className="secSec"  data-aos="zoom-in">
        <div className="container">
          <div className="row">
            <div className="col-md-12 ">
              <div className="secText">
                <figure>
                  <img
                    src={`${baseUrlImage}uploads/category/${data.Photo}`}
                    alt=""
                  />
                </figure>

                <p dangerouslySetInnerHTML={{ __html: data.Details }} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Consult />
      </>
      // </DocumentTitle>
  
  );
};

export default ServiceDetails;
