import React from "react";
import {baseUrlImage} from '../../index';

const Section = ({image, description}) => {
  return (
    <>
      <section className="secSec">
        <div className="container">
          <div className="row">
            <div className="col-md-12 ">
              <div className="secText">
                <figure>
                <img
                      src={`${baseUrlImage}uploads/createpage/${image}`}
                      alt=""
                    />
                </figure>

                <p
                      dangerouslySetInnerHTML={{ __html: description }}
                    />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section;
