import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { viewData } from "../../apiservice/ApiServices";
// import DocumentTitle from "react-document-title";
import { baseUrlImage } from "../../index";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Consult = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);

  const fetchData = async (id) => {
    const data = await viewData("ServiceDetail/marrige-problem");
    setData(data && data.relatedservice);
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  return (
    // <DocumentTitle title={`कुंडली मिलान | वेदिक एस्ट्रो सेंटर - विवाह के लिए सही जीवनसाथी का चयन ${id} | Vedic Astro`}>
      <>
      <Helmet>
  <title>कुंडली मिलान | वेदिक एस्ट्रो सेंटर - विवाह के लिए सही जीवनसाथी का चयन</title>
  <meta name="description" content="वेदिक एस्ट्रो सेंटर में कुंडली मिलान सेवाओं का लाभ उठाएं और अपने विवाह के लिए सही जीवनसाथी का चयन करें। कुंडली मिलान वैदिक ज्योतिष का एक महत्वपूर्ण हिस्सा है, जिसमें ग्रहों और नक्षत्रों के आधार पर दो व्यक्तियों की अनुकूलता का विश्लेषण किया जाता है। हमारी विशेषज्ञ ज्योतिषी टीम कुंडली मिलान, गुण मिलान, और विवाह योग्यता का सटीक और विस्तृत विश्लेषण प्रदान करती है। आज ही हमारी कुंडली मिलान सेवाओं का उपयोग करें और अपने जीवनसाथी के साथ सुखी जीवन की नींव रखें।"/>
  <meta name="keywords" content="कुंडली मिलान, विवाह कुंडली मिलान, गुण मिलान, ज्योतिष कुंडली मिलान, विवाह योग्यता, वेदिक ज्योतिष कुंडली मिलान, कुंडली मिलान सेवा, कुंडली मिलान विशेषज्ञ"/>
  </Helmet>
      
        <section className="consultUs">
          <div className="container">
            <div className="row">
              <div className="myHeading">
                <h2>समाधान</h2>
              </div>
              <div className="gridConsult">
                {data &&
                  data.map((val, index) => {
                    return (
                      <div className="consultCard card1" key={index}>
                        <div className="imgCar">
                          <figure>
                            <img
                              src={`${baseUrlImage}uploads/category/${val.Photo}`}
                              alt=""
                            />
                          </figure>
                        </div>
                        <h4>{val.name}</h4>
                        <p dangerouslySetInnerHTML={{ __html: val.Details }} />
                        <div className="readMore">
                          <Link to={`/consult/${val.alias}`}>
                           
                            <figure>
                              <img
                                src="assets/images/icons/readMore.svg"
                                alt=""
                              />
                            </figure>
                          </Link>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </section>
      </>
    // </DocumentTitle>
  );
};

export default Consult;
