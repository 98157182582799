import React, { useEffect, useState } from "react";
import { viewData } from "../../apiservice/ApiServices";
import { baseUrlImage } from "../../index";
import { useParams } from "react-router-dom";
import DocumentTitle from "react-document-title";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { baseUrl } from "../../index";

const ViewAll = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [detail, setDetail] = useState("");
  const [data, setData] = useState([]);
  const [menuData, setMenuData] = useState([]);
  const [isValid, setIsValid] = useState(true);

  const validatePhoneNumber = (value) => {
    const phoneRegex = /^\d{10}$/;
    setIsValid(phoneRegex.test(value));
  };

  const handleChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); 
    setPhone(value);
    validatePhoneNumber(value);
  };

  const { id } = useParams();
  const fetchData = async (id) => {
    const data = await viewData(`getDataMenu/${id}`);
    setData(data && data.data);
    setMenuData(data && data.menu[0]);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("Name", name);
    formData.append("Mobile", phone);
    formData.append("Details", detail);

    try {
      const response = await fetch(`${baseUrl}inquiryForm`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      if (data && data.message === "Your Inquiry Successfully Registered") {
        toast.success(data.message, {
          style: {
            backgroundColor: "green",
            color: "white",
          },
        });
        setName("");
        setPhone("");
        setDetail("");
      } else {
        toast.error(data && data.result.message, {
          style: {
            backgroundColor: "red",
            color: "white",
          },
        });
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred. Please try again.", {
        style: {
          backgroundColor: "red",
          color: "white",
        },
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // window.location.reload();
    fetchData(id);
  }, [id]);
  return (
    <DocumentTitle title={`${menuData.Menu} | Call : 9926653156`}>
      <>
        <section className="breadCreamp">
          <div className="container">
            <div className="breadTitle">
              <h1>{menuData.Menu}</h1>
              <p>
                <p>{menuData.Description}</p>
              </p>
            </div>
          </div>
        </section>

        {id === "ratna" ? (
          <section className="rashiRatn" data-aos="zoom-in">
            <div className="container">
              <div className="row">
                <div className="ratnGrid">
                  {data &&
                    data.map((val, index) => {
                      return (
                        <div className="ratnCard" key={index}>
                          <figure>
                            <img
                              src={`${baseUrlImage}uploads/createpage/${val.Image}`}
                              alt=""
                            />
                          </figure>
                          <div className="ratnText">
                            <h4>{val.Title}</h4>

                            <p
                              dangerouslySetInnerHTML={{ __html: val.Detail }}
                            />
                            <div className="rashiRead">
                              <Link
                                to="/inquiry"
                                data-toggle="modal"
                                data-target="plan-detail"
                              >
                                Inquiry Now
                              </Link>
                              <Link to={`/ratna/${val.CreatePageAlias}`}>
                                Read More
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="rashiRatn" data-aos="zoom-in">
            <div className="container">
              <div className="row">
                <div className="ratnGrid">
                  {data &&
                    data.map((val, index) => {
                      return (
                        <div className="ratnCard" key={index}>
                          <figure>
                            <img
                              src={`${baseUrlImage}uploads/createpage/${val.Image}`}
                              alt=""
                            />
                          </figure>
                          <div className="ratnText">
                            <h4>{val.Title}</h4>

                            <p
                              dangerouslySetInnerHTML={{ __html: val.Detail }}
                            />

                            <Link
                              to={`${
                                id === "yantra"
                                  ? `/yantra/${val.CreatePageAlias}`
                                  : id === "rashi"
                                  ? `/rashi/${val.CreatePageAlias}`
                                  : id === "hawanpoojan"
                                  ? `/hawanpoojan/${val.CreatePageAlias}`
                                  : `/rudraksha/${val.CreatePageAlias}`
                              } `}
                            >
                              Read More
                            </Link>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </section>
        )}
        {/* popup window for enquiry */}
        <div
          id="plan-detail"
          className="modal fade"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="my-modal-title"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="formInquiry popupInquiry">
                <h5>REQUEST A CONSULTING</h5>
                <p>
                  आपकी समस्या का समाधान यहा उपलब्ध है |समस्या के समाधान के लिए
                  हमसे संपर्क करे |
                </p>
                <div className="formCard">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <input
                        type="text"
                        onChange={(e) => setName(e.target.value)}
                        className="form-control"
                        placeholder="Full Name"
                        name="name"
                        required
                        value={name}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        onChange={handleChange}
                        maxLength={10}
                        className={`form-control ${isValid ? "" : "invalid"}`}
                        placeholder="Phone No. (10 digits)"
                        name="phone"
                        required
                        value={phone}
                        pattern="\d*"
                        title={
                          isValid
                            ? ""
                            : "Phone number must be exactly 10 digits."
                        }
                      />
                    </div>
                    <div className="form-group">
                      <textarea
                        onChange={(e) => setDetail(e.target.value)}
                        placeholder="Message"
                        value={detail}
                      />
                    </div>
                    <div className="form-group">
                      
                    </div>
                    <button
                      type="submit"
                      name="enquiry"
                      className="btn-started"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer autoClose={2000} />
      </>
   </DocumentTitle>
  );
};

export default ViewAll ;
