import React from 'react'

const PageNotFound = () => {
  return (
    <>
    <div>
    <img style={{ width: "900px" }} src='assets/images/page_not_found.png'/>
    </div>


    </>
  )
}

export default PageNotFound