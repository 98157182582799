import React from "react";
import { baseUrlImage } from "../../index";
import { useParams } from "react-router-dom";
import { viewData } from "../../apiservice/ApiServices";
import { useState, useEffect } from "react";
import DocumentTitle from "react-document-title";
import { Link } from "react-router-dom";
const ConsultUs = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);

  const fetchData = async (id) => {
    const data = await viewData("ServiceDetail/marrige-problem");
    setData(data && data.relatedservice);
  };

  useEffect(() => {
    fetchData(id);
    window.scroll(0, 0);
  }, [id]);
  useEffect(() => {
    // Update document title
    document.title = 'Consult Us for Free Online Astrology | Get Solutions from Expert Astrologers | Vedic Astro Center';

    // Remove existing meta tags if any
    const existingMetaTags = document.querySelectorAll('meta[name="description"], meta[name="keywords"], meta[property^="og"]');
    existingMetaTags.forEach(tag => tag.remove());

    // Create new meta tag for description
    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = 'Consult us for free online astrology and receive solutions from expert astrologers at Vedic Astro Center. Talk to our expert astrologers and benefit from the guidance of our scientific and Vedic astrology experts.';
    document.head.appendChild(metaDescription);

    // Create meta tag for keywords
    const metaKeywords = document.createElement('meta');
    metaKeywords.name = 'keywords';
    metaKeywords.content = 'astrology, astrotalk, online astrology, astrology reading, Indian astrology, free online astrology, live astrology, free astrology, best astrologer, expert astrologer consultation online, astrologer consultation on phone, astrologer service on phone, grahdarpan, kundali';
    document.head.appendChild(metaKeywords);

    // Create Open Graph meta tags for social sharing
    const ogTitle = document.createElement('meta');
    ogTitle.property = 'og:title';
    ogTitle.content = 'Consult Us for Free Online Astrology | Get Solutions from Expert Astrologers | Vedic Astro Center';
    document.head.appendChild(ogTitle);

    const ogDescription = document.createElement('meta');
    ogDescription.property = 'og:description';
    ogDescription.content = 'Consult us for free online astrology and receive solutions from expert astrologers at Vedic Astro Center. Talk to our expert astrologers and benefit from the guidance of our scientific and Vedic astrology experts.';
    document.head.appendChild(ogDescription);

    const ogType = document.createElement('meta');
    ogType.property = 'og:type';
    ogType.content = 'website';
    document.head.appendChild(ogType);

    const ogUrl = document.createElement('meta');
    ogUrl.property = 'og:url';
    ogUrl.content = 'https://vedicastrocenter.com/consult-us';
    document.head.appendChild(ogUrl);

    // Replace with your actual image URL
    const ogImage = document.createElement('meta');
    ogImage.property = 'og:image';
    // ogImage.content = `${baseUrlImage}assets/images/consult.jpg`;
    document.head.appendChild(ogImage);

    const ogImageAlt = document.createElement('meta');
    ogImageAlt.property = 'og:image:alt';
    ogImageAlt.content = 'Consult Us for Free Online Astrology';
    document.head.appendChild(ogImageAlt);

  }, []);

  return (
    // <DocumentTitle title="कुंडली मिलान | वेदिक एस्ट्रो सेंटर - विवाह के लिए सही जीवनसाथी का चयन| Vedic Astro">
    <>
      <section className="breadCreamp">
        <div className="container">
          <div className="breadTitle">
            <h1>समाधान</h1>
          </div>
        </div>
      </section>
      <section className="consultUs" data-aos="zoom-in">
        <div className="container">
          <div className="row">
            <div className="myHeading">
              <div className="gridConsult">
                {data &&
                  data.map((val, index) => {
                    return (
                      <div className="consultCard card1" key={index}>
                        <div className="imgCar">
                          <figure>
                            <img
                              src={`${baseUrlImage}uploads/category/${val.Photo}`}
                              alt=""
                            />
                          </figure>
                        </div>
                        <h4>{val.name}</h4>
                        <p
                          dangerouslySetInnerHTML={{ __html: val.Details }}
                        />
                        <div className="readMore">
                          <Link to={`/consult/${val.alias}`}>
                            {" "}
                            <figure>
                              <img
                                src="assets/images/icons/readMore.svg"
                                alt=""
                              />
                            </figure>
                          </Link>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
    // </DocumentTitle>
  );
};

export default ConsultUs;
