import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DocumentTitle from "react-document-title";
import { viewData } from "../../apiservice/ApiServices";
import { baseUrlImage } from "../../index";
import Section from "./Section";
import { Link } from "react-router-dom";

const HawanPuja = () => {

    const [allRatna, setAllRatna] = useState([]);
    const [ratna, setRatna] = useState([]);

    const { id } = useParams();
    const fetchData = async (id) => {
      const data = await viewData(`PoojanDetail/${id}`);
      setAllRatna(data && data.allpoojan);
      setRatna(data && data.poojandetail[0]);
    };

    useEffect(() => {
      document.title = ratna.MetaTitle
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.content = (ratna.MetaDescription);
      } else {
        const meta = document.createElement('meta');
        meta.name = 'description';
        meta.content = "वैदिक ज्योतिष केंद्र में वैदिक ज्योतिष की ज्ञान संपदा का अन्वेषण करें। विशेषज्ञ ज्योतिषीय मार्गदर्शन, सटीक जन्म कुंडली विश्लेषण और व्यक्तिगत राशिफल भविष्यवाणियां प्राप्त करें। हमारी व्यापक ज्योतिष सेवाओं के साथ अपने भाग्य को खोलें।" ;
        document.head.appendChild(meta);
      }
    }, [ratna]);
    
    useEffect(() => {
      window.scrollTo(0, 0);
      fetchData(id);
    }, [id]);
    
  return (
    // <DocumentTitle title={`${ratna.Title} | Vedic Astro`}>
    <>
    <section className="breadCreamp">
    <div className="container">
      <div className="breadTitle">
        <h1>{ratna.Title}</h1>
        {/* <p dangerouslySetInnerHTML={{ __html: ratna.Detail }}/> */}
      </div>
    </div>
  </section>
  
  <Section image={ratna.Image} description={ratna.Detail}/>


    <section className="rashiRatn"  data-aos="zoom-in">
    <div className="container">
      <div className="row">
        <div className="ratnGrid">
          {allRatna &&
            allRatna.map((val, index) => {
              return (
                <div className="ratnCard" key={index}>
                  <figure>
                    <img
                      src={`${baseUrlImage}uploads/createpage/${val.Image}`}
                      alt=""
                    />
                  </figure>
                  <div className="ratnText">
                    <h4>{val.Title}</h4>

                    <p
                      dangerouslySetInnerHTML={{ __html: val.Detail }}
                    />
                    <div className="rashiRead">
                      <Link to={`/hawanpoojan/${val.CreatePageAlias}`}>Read More</Link>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  </section>

  <br/>
    </>
    // </DocumentTitle>
  )
}

export default HawanPuja;

