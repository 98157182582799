import React, { useState, useEffect } from "react";
// import DocumentTitle from "react-document-title";
import { ToastContainer, toast } from "react-toastify";
// import ReCAPTCHA from "react-google-recaptcha";
import { baseUrl } from "../../index";
import DocumentTitle from "react-document-title";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import {baseUrlImage} from '../../index'
const FreeKundli = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [detail, setDetail] = useState("");
  const [dob, setDob] = useState("");
  const [time, setTime] = useState("");
  const [gendar, setGendar] = useState("");
  const [place, setPlace] = useState("");
  const [isValid, setIsValid] = useState(true);

  const validatePhoneNumber = (value) => {
    const phoneRegex = /^\d{10}$/;
    setIsValid(phoneRegex.test(value));
  };

  const handleChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setPhone(value);
    validatePhoneNumber(value);
  };

  const getCurrentDate = () => {
    const today = new Date();

    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // const handleReCAPTCHAChange = (token) => {
  //   setRecaptchaToken(token);
  // };

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
    window.scroll(0, 0);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("Name", name);
    formData.append("Mobile", phone);
    formData.append("Dob", dob);
    formData.append("Time", time);
    formData.append("Place", place);
    formData.append("Gendar", gendar);
    formData.append("Details", detail);

    try {
      const response = await fetch(`${baseUrl}FreeKundle`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      // console.log(data.message);
      if (data && data.message === "Successfully Registered") {
        toast.success(data.message, {
          style: {
            backgroundColor: "green",
            color: "white",
          },
        });
        setName("");
        setPhone("");
        setDetail("");
        setDob("");
        setGendar("");
        setPlace("");
        setTime("");
      } else {
        toast.error(data && data.result.message, {
          style: {
            backgroundColor: "red",
            color: "white",
          },
        });
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred. Please try again.", {
        style: {
          backgroundColor: "red",
          color: "white",
        },
      });
    }
  };


  useEffect(() => {
    // Update document title
    document.title = 'कुंडली विश्लेषण - निशुल्क सेवा | Vedic Astro | संपर्क करें 9926653156';

    // Remove existing meta tags if any
    const existingMetaTags = document.querySelectorAll('meta[name="description"]');
    existingMetaTags.forEach(tag => tag.remove());

    // Create new meta tag for description
    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = 'क्या आप कुंडली विश्लेषण की तलाश में हैं? Vedic Astro पर पाएँ निशुल्क कुंडली विश्लेषण सेवा और विशेषज्ञ परामर्श। जानें अपने जीवन के बारे में अधिक और भविष्य की संभावनाओं को समझें। निशुल्क सेवा के लिए संपर्क करें 9926653156।';
    document.head.appendChild(metaDescription);
    // Create meta tag for keywords
    const metaKeywords = document.createElement('meta');
    metaKeywords.name = 'keywords';
    metaKeywords.content = 'कुंडली विश्लेषण, निशुल्क कुंडली सेवा, कुंडली मिलान, वैदिक ज्योतिष, कुंडली परामर्श, ज्योतिष सेवा, जन्म कुंडली विश्लेषण, ज्योतिषी से संपर्क, कुंडली रिपोर्ट';
    document.head.appendChild(metaKeywords);
  
    // Create Open Graph meta tags for social sharing
    const ogTitle = document.createElement('meta');
    ogTitle.property = 'og:title';
    ogTitle.content = 'कुंडली विश्लेषण - निशुल्क सेवा | Vedic Astro | संपर्क करें 9926653156';
    document.head.appendChild(ogTitle);

    const ogDescription = document.createElement('meta');
    ogDescription.property = 'og:description';
    ogDescription.content = 'ऑनलाइन मुफ्त कुंडली प्राप्त करें। अपने व्यक्तित्व, जीवन घटनाओं और अधिक के लिए वैदिक ज्योतिषीय दृष्टिकोणों में विस्तारपूर्वक गहन विचार करें।';
    document.head.appendChild(ogDescription);

    const ogType = document.createElement('meta');
    ogType.property = 'og:type';
    ogType.content = 'website';
    document.head.appendChild(ogType);

    const ogUrl = document.createElement('meta');
    ogUrl.property = 'og:url';
    ogUrl.content = 'https://vedicastrocenter.com/freekundli';
    document.head.appendChild(ogUrl);

    const ogImage = document.createElement('meta');
    ogImage.property = 'og:image';
    ogImage.content = `${baseUrlImage}assets/images/kundali.jpg`; // Replace with your actual image URL
    document.head.appendChild(ogImage);

    const ogImageAlt = document.createElement('meta');
    ogImageAlt.property = 'og:image:alt';
    ogImageAlt.content = 'कुंडली विश्लेषण'; 
    document.head.appendChild(ogImageAlt);
  }, []);
  return (
    // <DocumentTitle title="मुफ्त कुंडली विश्लेषण | Vedic Astro">
      <>
      <Helmet>
            <title>फ्री कुंडली ऑनलाइन | आज ही अपनी मुफ्त जन्म कुंडली प्राप्त करें</title>
            <meta name="description" content="वेदिक एस्ट्रो सेंटर से अपनी मुफ्त कुंडली (जन्म कुंडली) विश्लेषण प्राप्त करें। हमारे सटीक और उपयोग में आसान ऑनलाइन टूल के साथ अपना ज्योतिषीय प्रोफाइल और अंतर्दृष्टि खोजें। आज ही अपनी फ्री कुंडली बनाएं!" />
    
        </Helmet>
        <section className="breadCreamp">
          <div className="container">
            <div className="breadTitle">
              <h1>मुफ्त कुंडली विश्लेषण</h1>
             
            </div>
          </div>
        </section>
        <section
          className="kundalipaid kundalipaidkundalipge"
          data-aos="zoom-in-up"
        >
          <div className="row">
            <div className="container">
              <div className="col-md-7">
                <div className="text_kundaliwraptext">
                  <h4>मुफ्त कुंडली विश्लेषण</h4>
                  <p>
                    जन्म कुंडली व्यक्ति के जीवन का मानचित्र होती है। इसमें
                    ग्रहों और नक्षत्रों की स्थिति का विवरण होता है। कुंडली के
                    माध्यम से आप अपने जीवन के विभिन्न पहलुओं का विश्लेषण कर सकते
                    हैं। यह आपको स्वास्थ्य, करियर, और व्यक्तिगत संबंधों के बारे
                    में महत्वपूर्ण जानकारी प्रदान करती है। कुंडली विश्लेषण के
                    लिए किसी विशेषज्ञ ज्योतिषी की सहायता लें। हमारे द्वारा
                    प्रदान किया गया मुफ्त कुंडली विश्लेषण आपकी समस्याओं का
                    समाधान खोजने में सहायक हो सकता है।
                  </p>
                </div>
              </div>
              <div className="col-md-5">
                <div className="formInquiry popupInquiry">
                  <div className="formCard">
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <input
                          type="text"
                          onChange={(e) => setName(e.target.value)}
                          className="form-control"
                          placeholder="Full Name"
                          name="name"
                          required
                          value={name}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          onChange={handleChange}
                          maxLength={10}
                          className={`form-control ${isValid ? "" : "invalid"}`}
                          placeholder="Phone No. (10 digits)"
                          name="phone"
                          required
                          value={phone}
                          pattern="\d*"
                          title={
                            isValid
                              ? ""
                              : "Phone number must be exactly 10 digits."
                          }
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="Date"
                          onChange={(e) => setDob(e.target.value)}
                          className="form-control"
                          placeholder="Date of Birth"
                          name="dob"
                          required
                          value={dob}
                          max={getCurrentDate()}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="time"
                          onChange={(e) => setTime(e.target.value)}
                          className="form-control"
                          placeholder="Time (HH:MM)"
                          name="time"
                          required
                          value={time}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          onChange={(e) => setPlace(e.target.value)}
                          className="form-control"
                          placeholder="Place"
                          name="place"
                          required
                          value={place}
                        />
                      </div>
                      <div className="form-group">
                        <select
                          onChange={(e) => setGendar(e.target.value)}
                          className="form-control"
                          placeholder="Gender"
                          name="gender"
                          required
                          value={gendar}
                        >
                          <option value="" disabled>
                            Select Gender
                          </option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </select>
                      </div>

                      <div className="form-group">
                        <textarea
                          onChange={(e) => setDetail(e.target.value)}
                          placeholder="Message"
                          value={detail}
                        />
                      </div>
                      {/* <div className="form-group">
                  <ReCAPTCHA
                    sitekey="6LfgMQUqAAAAALLlBC_TDFlDG-gXuU6oi3nYK1pN"
                    onChange={handleReCAPTCHAChange}
                  />
                </div> */}
                      <button
                        type="submit"
                        name="enquiry"
                        className="btn-started"
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <ToastContainer autoClose={2000} />
      </>
    // </DocumentTitle>
  );
};

export default FreeKundli;
